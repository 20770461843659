<template>
    <div class="detail_page" v-if="productObject">
        <div class="model_1" v-if="productObject.styleModel == 1">
            <div class="info_wrap flex_align_center">
                <div class="info_content flex_col">
                    <h1 class="en">{{productObject.name_en}}</h1>
                    <h2 class="cn">{{productObject.name_cn}}</h2>
                    <img src="@/assets/pc/img/tag_5.png" alt="" class="tag">
                    <div class="copywriting">
                        <div class="remarks">{{productObject.remarks}}</div>
                        <p>{{productObject.copywriting}}</p>
                    </div>
                </div>
                <img class="product_img" :src="productObject.posterImgs[0]" alt="">
            </div>
            <div class="poster_wrap flex_col flex_align_center">
                <img :src="productObject.posterImgs[1]" alt="" class="poster_img_1">
                <img :src="productObject.posterImgs[2]" alt="" class="poster_img_2">
                <div class="wrapper flex_align_center">
                    <img :src="productObject.posterImgs[3]" alt="" class="poster_img_3">
                    <img src="@/assets/pc/img/next_3.png" class="tag" alt="">
                    <div class="poster_text_wrap w_h_100 flex_col flex_justify_end">
                        <h1>Doitforchange</h1>
                        <p>
                            经典信封包装</br>
                            外出携带不尴尬</br>
                            小解解都爱的套套
                        </p>
                        <img :src="productObject.posterImgs[4]" alt="" class="poster_img_4">
                    </div>
                </div>
            </div>
            <div class="complimentary_card_wrap">
                <div class="title_wrap flex_align_center flex_justify_between">
                    <span class="en">Complimentary card</span>
                    <span class="cn">附赠卡片</span>
                    <img class="absolute_l_b" src="@/assets/pc/img/tag_6.png" alt="">
                </div>
                <div class="content_wrap flex_justify_between">
                    <div class="text_wrap flex_col flex_align_center">
                        <h2>爱的zi式</h2>
                        <img src="@/assets/pc/img/tag_7.png" alt="">
                        <p>
                            多名设计师联合创作</br>
                            每个信封都暗藏惊喜
                        </p>
                    </div>
                    <div class="card_wrap">
                        <img v-for="(item, index) of productObject.cardImgs" :src="item" alt="">
                    </div>
                </div>
            </div>
        </div>
        <div class="model_2" v-if="productObject.styleModel == 2">
            <div class="info_wrap">
                <h1>More Details</h1>
                <div class="info_content flex_col">
                    <h2 class="en">{{productObject.name_en}}</h2>
                    <h3 class="cn">{{productObject.name_cn}}</h3>
                    <img :src="productObject.posterImgs[0]" alt="" class="product_img">
                    <div class="copywriting">{{productObject.copywriting}}</div>
                </div>
            </div>
            <div class="poster_wrap">
                <div class="wrapper flex_justify_between">
                    <div class="poster_text_wrap flex_col">
                        <h4 class="poster_cn">{{productObject.posterCn}}</h4>
                        <div class="poster_en">{{productObject.posterEn}}<img class="absolute_l_b" src="@/assets/pc/img/tag_8.png" alt="">
                        </div>
                        <div class="poster_remarks">{{productObject.posterRemarks}}</div>
                        <img :src="productObject.posterImgs[1]" alt="" class="poster_img_1">
                    </div>
                    <img :src="productObject.posterImgs[2]" alt="" class="poster_img_2">
                </div>
                <div class="wrapper_2">
                    <img :src="productObject.posterImgs[3]" alt="" class="poster_img_3">
                    <div class="poster_img_4_wrap flex_col">
                        <img :src="productObject.posterImgs[4]" alt="" class="poster_img_4">
                        <p class="poster_text">{{productObject.posterText1}}</p>
                    </div>
                    <div class="poster_img_5_wrap flex_align_end absolute_r_b">
                        <p class="poster_text">{{productObject.posterText2}}</p>
                        <img :src="productObject.posterImgs[5]" alt="" class="poster_img_5">
                    </div>
                    <img src="@/assets/pc/img/tag_9.png" alt="" class="tag">
                </div>
            </div>
        </div>
        <custom-foot></custom-foot>
    </div>
</template>

<script>
    import { pcCosBaseUrl } from '@/config/evn.js'
    export default {
        name: 'detail',
        data() {
            return {
                poster_en: 'Feel\nDifferent Happiness',
                posterText: '萃取植物成分\n误入空中也不慌',
                posterText2: '用途自由\n不仅仅只是钥匙扣',
                productActiveIndex: -1,
                pcCosBaseUrl,
                productList: [
                {
                        id: 1,
                        styleModel: 1,
                        name_cn: '螺纹颗粒款',
                        name_en: 'Thread Particle',
                        remarks: '',
                        copywriting: '每一个颗粒都懂你的点\n让每一次的碰撞都有回应',
                        posterImgs: [
                            pcCosBaseUrl+'product_01_poster_1.png',
                            pcCosBaseUrl+'product_01_poster_2.png',
                            pcCosBaseUrl+'product_01_poster_3.png',
                            pcCosBaseUrl+'product_01_poster_4.png',
                            pcCosBaseUrl+'product_01_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl+'complimentary_card_05.png',
                            pcCosBaseUrl+'complimentary_card_06.png',
                            pcCosBaseUrl+'complimentary_card_07.png',
                            pcCosBaseUrl+'complimentary_card_08.png'
                        ]
                    }, {
                        id: 2,
                        styleModel: 1,
                        name_cn: '超薄款',
                        name_en: 'Ultra Thin',
                        remarks: '',
                        copywriting: '似有若无带若无物\n薄度导热 给你带来真实触感',
                        posterImgs: [
                            pcCosBaseUrl+'product_02_poster_1.png',
                            pcCosBaseUrl+'product_02_poster_2.png',
                            pcCosBaseUrl+'product_02_poster_3.png',
                            pcCosBaseUrl+'product_02_poster_4.png',
                            pcCosBaseUrl+'product_02_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl+'complimentary_card_09.png',
                            pcCosBaseUrl+'complimentary_card_10.png',
                            pcCosBaseUrl+'complimentary_card_11.png',
                            pcCosBaseUrl+'complimentary_card_12.png'
                        ]
                    }, {
                        id: 3,
                        styleModel: 1,
                        name_cn: '玻尿酸款',
                        name_en: 'Hyaluronic Acid',
                        remarks: '',
                        copywriting: '添加水溶性玻尿酸\n超水润、不油腻、易清洗',
                        posterImgs: [
                            pcCosBaseUrl+'product_03_poster_1.png',
                            pcCosBaseUrl+'product_03_poster_2.png',
                            pcCosBaseUrl+'product_03_poster_3.png',
                            pcCosBaseUrl+'product_03_poster_4.png',
                            pcCosBaseUrl+'product_03_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl+'complimentary_card_05.png',
                            pcCosBaseUrl+'complimentary_card_10.png',
                            pcCosBaseUrl+'complimentary_card_11.png',
                            pcCosBaseUrl+'complimentary_card_13.png'
                        ]
                    }, {
                        id: 4,
                        styleModel: 1,
                        name_cn: '超润超薄款',
                        name_en: 'Super Moist Ultra-Thin',
                        remarks: '',
                        copywriting: '超薄+超润=乐趣200%',
                        posterImgs: [
                            pcCosBaseUrl+'product_04_poster_1.png',
                            pcCosBaseUrl+'product_04_poster_2.png',
                            pcCosBaseUrl+'product_04_poster_3.png',
                            pcCosBaseUrl+'product_04_poster_4.png',
                            pcCosBaseUrl+'product_04_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl+'complimentary_card_12.png',
                            pcCosBaseUrl+'complimentary_card_07.png',
                            pcCosBaseUrl+'complimentary_card_03.png',
                            pcCosBaseUrl+'complimentary_card_02.png'
                        ]
                    }, {
                        id: 5,
                        styleModel: 1,
                        name_cn: '持久延迟款',
                        name_en: 'Long Delay Payment',
                        remarks: 'PHYSICAL DELAY',
                        copywriting: '区别于传统加厚物理加时套\n只在敏感部位加厚 套身依旧薄至0.03',
                        posterImgs: [
                            pcCosBaseUrl+'product_05_poster_1.png',
                            pcCosBaseUrl+'product_05_poster_2.png',
                            pcCosBaseUrl+'product_05_poster_3.png',
                            pcCosBaseUrl+'product_05_poster_4.png',
                            pcCosBaseUrl+'product_05_poster_5.png'
                        ],
                        cardImgs: [
                            pcCosBaseUrl+'complimentary_card_01.png',
                            pcCosBaseUrl+'complimentary_card_02.png',
                            pcCosBaseUrl+'complimentary_card_03.png',
                            pcCosBaseUrl+'complimentary_card_04.png'
                        ]
                    }, {
                        id: 6,
                        styleModel: 2,
                        name_cn: '润滑剂',
                        name_en: 'Lubricant',
                        copywriting: '“ 让你感受不一样的张弛有度 ”',
                        posterCn: 'Ci溜Ci溜',
                        posterEn: 'Feel\nDifferent Happiness',
                        posterRemarks: 'Ice feeling / Heat sensation /',
                        posterText1: '萃取植物成分\n误入空中也不慌',
                        posterText2: '虽然身心火热\n但总有一处保持冰凉',
                        posterImgs: [
                            pcCosBaseUrl+'product_06_poster_1.png',
                            pcCosBaseUrl+'product_06_poster_2.png',
                            pcCosBaseUrl+'product_06_poster_3.png',
                            pcCosBaseUrl+'product_06_poster_4.png',
                            pcCosBaseUrl+'product_06_poster_5.png',
                            pcCosBaseUrl+'product_06_poster_6.png'
                        ]
                    }, {
                        id: 7,
                        styleModel: 2,
                        name_cn: '钥匙扣',
                        name_en: 'Key Buckle',
                        copywriting: '“ 穿过你的心门 只差一个时机 ”',
                        posterCn: '扣扣相穿',
                        posterEn: 'KEY CHAIN\nDecorate everything',
                        posterRemarks: 'bag / key / zip /',
                        posterText1: '尼龙加刺绣工艺\n怎么拉扯都完好无损',
                        posterText2: '用途自由\n不仅仅只是钥匙扣',
                        posterImgs: [
                            pcCosBaseUrl+'product_07_poster_1.png',
                            pcCosBaseUrl+'product_07_poster_2.png',
                            pcCosBaseUrl+'product_07_poster_3.png',
                            pcCosBaseUrl+'product_07_poster_4.png',
                            pcCosBaseUrl+'product_07_poster_5.png',
                            pcCosBaseUrl+'product_07_poster_6.png'
                        ]
                    }, {
                        id: 8,
                        styleModel: 2,
                        name_cn: '丝巾',
                        name_en: 'Silk Scarf',
                        copywriting: '“ 穿过你的心门 只差一个时机”',
                        posterCn: '一丝不挂',
                        posterEn: 'These\nthings on you',
                        posterRemarks: 'Tuba / trumpet /',
                        posterText1: '顺滑的不止是丝巾\n还有少女的身体',
                        posterText2: '这是一条\n随时散发魅力的迷人绑带',
                        posterImgs: [
                            pcCosBaseUrl+'product_08_poster_1.png',
                            pcCosBaseUrl+'product_08_poster_2.png',
                            pcCosBaseUrl+'product_08_poster_3.png',
                            pcCosBaseUrl+'product_08_poster_4.png',
                            pcCosBaseUrl+'product_08_poster_5.png',
                            pcCosBaseUrl+'product_08_poster_6.png'
                        ]
                    }, {
                        id: 9,
                        styleModel: 2,
                        name_cn: 'XX胸贴',
                        name_en: 'Nipples Paste',
                        copywriting: '“ 穿过你的心门 只差一个时机”',
                        posterCn: 'XX胸贴',
                        posterEn: 'These\nthings on you',
                        posterRemarks: 'Tuba / trumpet /',
                        posterText1: '少女的躯体犹如春水丝滑\n双手握住两座礁石 心甘情愿\n淹死在你怀里',
                        posterText2: '我有太多的浪漫\n都想在你身上施展',
                        posterImgs: [
                            pcCosBaseUrl+'product_09_poster_1.png',
                            pcCosBaseUrl+'product_09_poster_2.png',
                            pcCosBaseUrl+'product_09_poster_3.png',
                            pcCosBaseUrl+'product_09_poster_4.png',
                            pcCosBaseUrl+'product_09_poster_5.png',
                            pcCosBaseUrl+'product_09_poster_6.png'
                        ]
                    }, {
                        id: 10,
                        styleModel: 2,
                        name_cn: '福袋',
                        name_en: 'Blessing Bag',
                        copywriting: '“ 穿过你的心门 只差一个时机”',
                        posterCn: '福满多多',
                        posterEn: 'These\nthings on you',
                        posterRemarks: 'Tuba / trumpet /',
                        posterText1: '装你所想无尽可能\n我们期待变化却又畏惧行动\n我们期待改变却又害怕未知',
                        posterText2: '一份福袋寄托一份幸运\n一份福袋蕴含一丝期望',
                        posterImgs: [
                            pcCosBaseUrl+'product_10_poster_1.png',
                            pcCosBaseUrl+'product_10_poster_2.png',
                            pcCosBaseUrl+'product_10_poster_3.png',
                            pcCosBaseUrl+'product_10_poster_4.png',
                            pcCosBaseUrl+'product_10_poster_5.png',
                            pcCosBaseUrl+'product_10_poster_6.png'
                        ]
                    }
                ]
            }
        },
        created() {
            let id = this.$route.query.id
            if (id) {
                this.initById(JSON.parse(id))
            }
        },
        computed: {
            productObject() {
                let productActiveIndex = this.productActiveIndex
                if (productActiveIndex < 0) return undefined
                return this.productList[productActiveIndex]
            }
        },
        methods: {
            initById(id) {
                this.productActiveIndex = -1
                let productList = this.productList
                let maxLen = productList.length
                for (let i = 0; i < maxLen; i++) {
                    if (productList[i].id === id) {
                        this.productActiveIndex = i
                        break
                    }
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '@/style/mixin.scss';

    .detail_page {
        text-align: left;
        white-space: pre-wrap;
        .model_1 {
            h1 {
                font-family: HurmeGeometricSans3-Black;
                font-size: formatPX_1920(48px);
            }

            h2 {
                font-family: SourceHanSansCN-Bold;
                font-size: formatPX_1920(48px);
            }

            .info_wrap {
                width: 100%;
                height: formatPX_1920(1080px);
                background-color: #EDEDED;
                display: flex;
                padding: formatPX_1920(88px) formatPX_1920(280px) formatPX_1920(65px);

                .info_content {
                    width: formatPX_1920(740px);
                    height: 100%;
                    padding-top: formatPX_1920(30px);

                    .en {
                        line-height: formatPX_1920(58px);
                    }

                    .cn {
                        letter-spacing: formatPX_1920(8px);
                        margin: formatPX_1920(50px) 0;
                    }

                    .tag {
                        width: formatPX_1920(458px);
                        height: formatPX_1920(458px);
                        transform: translateX(formatPX_1920(-40px));
                    }

                    .copywriting {
                        line-height: formatPX_1920(48px);
                        font-family: SourceHanSansCN-Medium;
                        font-size: formatPX_1920(24px);
                        margin-top: auto;

                        .remarks {
                            line-height: formatPX_1920(37px);
                            font-family: HurmeGeometricSans3-Bold;
                            font-size: formatPX_1920(30px);
                            margin-bottom: formatPX_1920(20px);
                        }
                    }
                }

                .product_img {
                    width: formatPX_1920(620px);
                    height: 100%;
                }
            }

            .poster_wrap {
                height: formatPX_1920(2050px);
                padding-top: formatPX_1920(44px);

                .poster_img_1 {
                    width: formatPX_1920(1030px);
                    height: formatPX_1920(144px);
                }

                .poster_img_2 {
                    width: formatPX_1920(1208px);
                    height: formatPX_1920(799px);
                    margin: formatPX_1920(68px) 0 formatPX_1920(190px);
                }

                .wrapper {
                    width: 100%;
                    height: formatPX_1920(762px);
                    padding-left: formatPX_1920(280px);

                    .poster_img_3 {
                        width: formatPX_1920(510px);
                        height: 100%;
                    }

                    .tag {
                        width: formatPX_1920(72px);
                        height: formatPX_1920(68px);
                        margin: 0 formatPX_1920(266px) 0 formatPX_1920(98px);
                    }

                    .poster_text_wrap {
                        h1 {
                            line-height: formatPX_1920(58px);
                            letter-spacing: 1.5px;
                        }

                        p {
                            line-height: formatPX_1920(48px);
                            font-family: SourceHanSansCN-Medium;
                            font-size: formatPX_1920(18px);
                            color: #999;
                            margin: formatPX_1920(15px) 0;
                        }

                        .poster_img_4 {
                            width: formatPX_1920(413px);
                            height: formatPX_1920(413px);
                        }
                    }
                }
            }

            .complimentary_card_wrap {
                padding: formatPX_1920(200px) 0 formatPX_1920(147px) formatPX_1920(360px);

                .title_wrap {
                    position: relative;
                    width: formatPX_1920(1208px);
                    height: formatPX_1920(75px);
                    padding-right: formatPX_1920(40px);

                    .en {
                        font-family: HurmeGeometricSans3-Bold;
                        font-size: formatPX_1920(36px);
                    }

                    .cn {
                        font-size: formatPX_1920(30px);
                        color: #2C2C2C;
                    }

                    img {
                        width: 100%;
                        height: formatPX_1920(26.5px);
                    }
                }
                .content_wrap {
                    width: formatPX_1920(1208px);
                    height: formatPX_1920(684px);
                    margin-top: formatPX_1920(87px);
                    .text_wrap {
                        width: formatPX_1920(400px);
                        height: 100%;
                        h2 {
                            letter-spacing: 2px;
                        }
                        img {
                            width: formatPX_1920(85px);
                            height: formatPX_1920(208px);
                            margin: formatPX_1920(112px) 0 formatPX_1920(224px);
                        }
                        p {
                            line-height: formatPX_1920(52px);
                            font-family: SourceHanSansCN-Medium;
                            font-size: formatPX_1920(28px);
                            color: #2C2C2C;
                            letter-spacing: 2px;
                        }
                    }
                    .card_wrap {
                        width: formatPX_1920(684px);
                        height: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-content: space-between;
                        img {
                            width: formatPX_1920(330px);
                            height: formatPX_1920(330px);
                        }
                    }
                }
            }
        }
        .model_2 {
            .info_wrap {
                padding-top: formatPX_1920(88px);
                h1 {
                    line-height: formatPX_1920(106px);
                    font-family: HurmeGeometricSans3-Bold;
                    font-size: formatPX_1920(88px);
                    padding-left: formatPX_1920(280px);
                }
                .info_content {
                    position: relative;
                    width: formatPX_1920(997px);
                    padding-top: formatPX_1920(15px);
                    margin-left: formatPX_1920(643px);
                    .en {
                        line-height: formatPX_1920(72px);
                        font-family: HurmeGeometricSans3-Bold;
                        font-size: formatPX_1920(60px);
                    }
                    .cn {
                        font-family: SourceHanSansCN-Medium;
                        font-size: formatPX_1920(48px);
                        margin: formatPX_1920(16px) 0 formatPX_1920(47px);
                    }
                    .product_img {
                        width: formatPX_1920(986px);
                        height: formatPX_1920(660px);
                        margin-left: auto;
                    }
                    .copywriting {
                        position: absolute;
                        right: 0;
                        bottom: formatPX_1920(707px);
                        font-family: SourceHanSansCN-Medium;
                        font-size: formatPX_1920(24px);
                    }
                }
            }
            .poster_wrap {
                padding: formatPX_1920(84px) 0 formatPX_1920(111px);
                .wrapper {
                    height: formatPX_1920(984px);
                    padding: 0 formatPX_1920(280px);
                    .poster_text_wrap {
                        width: formatPX_1920(446px);
                        height: 100%;
                        padding-top: formatPX_1920(45px);
                        .poster_cn {
                            font-family: SourceHanSansCN-Bold;
                            font-size: formatPX_1920(38px);
                        }
                        .poster_en {
                            position: relative;
                            line-height: formatPX_1920(48px);
                            font-family: HurmeGeometricSans3-SemiBold;
                            font-size: formatPX_1920(30px);
                            padding-bottom: formatPX_1920(15px);
                            margin: formatPX_1920(55px) 0 formatPX_1920(30px);
                            img {
                                width: 100%;
                                height: formatPX_1920(21px);
                            }
                        }
                        .poster_remarks {
                            line-height: formatPX_1920(29px);
                            font-family: HurmeGeometricSans3-Light;
                            font-size: formatPX_1920(24px);
                        }
                        .poster_img_1 {
                            width: formatPX_1920(466px);
                            height: formatPX_1920(604px);
                            margin-top: auto;
                        }
                    }
                    .poster_img_2 {
                        width: formatPX_1920(652px);
                        height: 100%;
                    }
                }
                .wrapper_2 {
                    position: relative;
                    width: formatPX_1920(1360px);
                    height: formatPX_1920(845px);
                    margin: formatPX_1920(162px) auto 0;
                    .poster_img_3 {
                        width: formatPX_1920(564px);
                        height: 100%;
                    }
                    .poster_img_4_wrap {
                        position: absolute;
                        top: 0;
                        left: formatPX_1920(634px);
                        width: formatPX_1920(328px);
                        line-height: formatPX_1920(48px);
                        font-family: SourceHanSansCN-Medium;
                        font-size: formatPX_1920(18px);
                        .poster_img_4 {
                            width: 100%;
                            height: formatPX_1920(424px);
                            margin-bottom: formatPX_1920(15px);
                        }

                    }
                    .poster_img_5_wrap {
                        height: formatPX_1920(424px);
                        line-height: formatPX_1920(60px);
                        font-family: SourceHanSansCN-Medium;
                        font-size: formatPX_1920(22px);
                        text-align: right;
                        .poster_img_5 {
                            width: formatPX_1920(328px);
                            height: 100%;
                            margin-left: formatPX_1920(30px)
                        }
                        p {
                            transform: translateY(formatPX_1920(19px));
                        }
                    }
                    .tag {
                        position: absolute;
                        right: formatPX_1920(24px);
                        top: 0;
                        width: formatPX_1920(20px);
                        height: formatPX_1920(72px);
                    }
                }
            }
        }
    }
</style>